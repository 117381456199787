import styled from "@emotion/styled"
import { Flex } from "@rebass/grid/emotion"
import theme from "../../../../../../nutrafol-ui-kit/styles/theme"

export const FooterContainer = styled.div`
  background-color: ${theme.wild.color.nova.base.black};

  &[data-mousedown] {
    *:focus {
      outline: none !important;
    }
  }
`

export const MainBox = styled.div`
  width: 100%;
  max-width: 1442px;
  margin: auto;
  padding: 48px 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  @media (min-width: 768px) {
    padding: 48px 32px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: 1024px) {
    padding: 80px 32px;
    grid-template-columns: repeat(10, 1fr);
  }

  .left {
    @media (min-width: 768px) {
      grid-area: 1 / 1 / 2 / 6;
    }

    @media (min-width: 1024px) {
      grid-area: 1 / 1 / 2 / 8;
    }

    .grid-container {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .content {
        @media (min-width: 768px) {
          margin-right: 65px;
        }

        @media (min-width: 1024px) {
          margin-right: 235px;
        }

        p {
          color: ${theme.wild.color.nova.base.white};

          &.text {
            margin-bottom: 8px;

            @media (min-width: 768px) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  .right {
    @media (min-width: 768px) {
      grid-area: 1 / 6 / 2 / 9;
    }

    @media (min-width: 1024px) {
      grid-area: 1 / 8 / 2 / 11;
    }
  }
`

export const LogoWrap = styled.div`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 40px;
  }

  @media (min-width: 1024px) {
    margin-right: 80px;
  }

  svg {
    width: 48px;
    height: 48px;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }

    @media (min-width: 1024px) {
      width: 54px;
      height: 54px;
    }

    path {
      fill: ${theme.wild.color.nova.base.white};
    }
  }
`

export const FooterWrap = styled(Flex)``

export const StyledLinks = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    margin-right: 100px;
  }

  .section-header {
    margin-bottom: 16px;
    color: ${theme.wild.color.nova.transparency.white[60]};
  }

  .link {
    line-height: 1.3;
    margin-bottom: 16px;

    span {
      display: inline-block;
      color: ${theme.wild.color.nova.base.white};
    }
  }

  .link:last-of-type {
    margin: 0;
  }
`
